import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date.service';

@Pipe({
  name: 'dateFormat',
})
export class DatePipe implements PipeTransform {
  constructor(private dateService: DateService) {
  }

  transform(
    value: string,
    format = 'DD/MM/YYYY',
    timeZoneId = 'america/sao_paulo',
  ): string {
    if (!value) {
      return '';
    }
    return this.dateService.formatDateTimeToLocal(value, format, timeZoneId);
  }
}

<div class="center" style="position: relative">
  <ngx-file-drop (onFileDrop)="dropped($event)" (onFileLeave)="fileLeave($event)" (onFileOver)="fileOver($event)"
                 contentClassName="file-drop-zone"
                 dropZoneClassName="file-drop-zone" dropZoneLabel="Solte o arquivo aqui">
    <ng-template let-openFileSelector="openFileSelector" ngx-file-drop-content-tmp>
      <button (click)="openFileSelector()" [disabled]="loading" color="accent" mat-raised-button type="button">
        <mat-icon *ngIf="iconButton" matTooltip="Icone">{{ iconButton }}</mat-icon>
        <span [innerHTML]="titleButton"></span>
      </button>
      <button (click)="removeFile();$event.stopPropagation();" *ngIf="showRemoveButton" color="primary"
              mat-icon-button
              type="button">
        <mat-icon matTooltip="Remover arquivo">close</mat-icon>
      </button>
    </ng-template>
  </ngx-file-drop>
</div>

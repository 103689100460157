import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

// Definindo as interfaces para valores válidos
export type FlexDirection = 'row' | 'row-wrap' | 'row-reverse' | 'column' | 'column-reverse';
export type FlexGap = 4 | 8 | 12 | 16 | 20 | 24 | 28 | 32 | 36 | 40;
export type FlexJustify = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
export type FlexAlignItems = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
export type FlexAlignContent = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'stretch';

@Directive({
  selector: '[marLayout]'
})
export class FlexDirective implements OnChanges {
  /**
   * Define a direção do contêiner flexível.
   * Valores válidos: 'row', 'row-reverse', 'column', 'column-reverse'.
   * @type {FlexDirection}
   */
  @Input() flexDirection?: FlexDirection;
  @Input() marLayout?: FlexDirection;

  /**
   * Define o espaçamento entre os itens no contêiner flexível.
   * Valores válidos: 4, 8, 12, 16, 20, 24, 28, 32, 36, 40 (em pixels).
   * @type {FlexGap}
   */
  @Input() flexGap?: FlexGap;

  /**
   * Define como os itens são justificados no contêiner flexível.
   * Valores válidos: 'flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly'.
   * @type {FlexJustify}
   */
  @Input() flexJustify?: FlexJustify;

  /**
   * Define como os itens são alinhados no eixo transversal (perpendicular à direção do contêiner).
   * Valores válidos: 'flex-start', 'flex-end', 'center', 'stretch', 'baseline'.
   * @type {FlexAlignItems}
   */
  @Input() flexAlignItems?: FlexAlignItems;

  /**
   * Define como as linhas são distribuídas no contêiner flexível quando há múltiplas linhas.
   * Valores válidos: 'flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'stretch'.
   * @type {FlexAlignContent}
   */
  @Input() flexAlignContent?: FlexAlignContent;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.marLayout) {
      this.flexDirection = this.marLayout;
    }
    this.removeClasses();
    this.addClasses();
  }

  private removeClasses(): void {
    const classList = this.el.nativeElement.classList;
    const flexClasses = Array.from(classList).filter((className: string) => className.startsWith('layout-'));
    flexClasses.forEach((className: string) => {
      this.renderer.removeClass(this.el.nativeElement, className);
    });
  }

  private addClasses(): void {

    this.renderer.addClass(this.el.nativeElement, `layout-flex`);

    if (this.flexDirection) {
      this.renderer.addClass(this.el.nativeElement, `layout-flex-direction-${ this.flexDirection }`);
    }
    if (this.flexGap) {
      this.renderer.addClass(this.el.nativeElement, `layout-gap-${ this.flexGap }`);
    }
    if (this.flexJustify) {
      this.renderer.addClass(this.el.nativeElement, `layout-justify-content-${ this.flexJustify }`);
    }
    if (this.flexAlignItems) {
      this.renderer.addClass(this.el.nativeElement, `layout-align-items-${ this.flexAlignItems }`);
    }
    if (this.flexAlignContent) {
      this.renderer.addClass(this.el.nativeElement, `layout-align-content-${ this.flexAlignContent }`);
    }
  }
}


export type FlexGrow = 0 | 1 | 2 | 3 | 4 | 5;
export type FlexShrink = 0 | 1 | 2 | 3 | 4 | 5;
export type FlexBasis =
  'auto'
  | 'inherit'
  | 'initial'
  | 'unset'
  | 'content'
  | 'min-content'
  | 'max-content'
  | 'fit-content'
  | string;
export type FlexAlignSelf = 'auto' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
export type FlexOrder = number;

@Directive({
  selector: '[marFlex]'
})
export class FlexChildDirective implements OnChanges {
  /**
   * Define o fator de crescimento do item flexível.
   * O valor padrão é 0, o que significa que o item não crescerá além de seu tamanho inicial.
   * Valores válidos: 0, 1, 2, 3, 4, 5.
   * Um valor maior permite que o item cresça mais em relação aos outros itens flexíveis.
   * @type {FlexGrow}
   */
  @Input() flexGrow?: FlexGrow;
  @Input() marFlex?: string;

  /**
   * Define a capacidade do item flexível de encolher.
   * O valor padrão é 1, permitindo que o item encolha se necessário.
   * Valores válidos: 0, 1, 2, 3, 4, 5.
   * Um valor maior permite que o item encolha mais em relação aos outros itens flexíveis.
   * @type {FlexShrink}
   */
  @Input() flexShrink?: FlexShrink;

  /**
   * Define a base de flexibilidade do item.
   * Pode ser um valor de tamanho (ex: '20%', '100px') ou uma das palavras-chave especiais.
   * Valores válidos: 'auto', 'inherit', 'initial', 'unset', 'content', 'min-content', 'max-content', 'fit-content', ou um valor de tamanho CSS válido.
   * 'auto' é o valor padrão, que ajusta o tamanho do item baseado em seu conteúdo.
   * @type {FlexBasis}
   */
  @Input() flexBasis?: FlexBasis;

  /**
   * Define o alinhamento do item em relação aos outros itens dentro do mesmo contêiner flexível.
   * Sobrescreve o alinhamento definido pelo contêiner.
   * Valores válidos: 'auto', 'flex-start', 'flex-end', 'center', 'baseline', 'stretch'.
   * @type {FlexAlignSelf}
   */
  @Input() flexAlignSelf?: FlexAlignSelf;

  /**
   * Define a ordem do item flexível em relação aos outros itens no contêiner.
   * O valor padrão é 0. Itens com ordem menor aparecem antes.
   * Valores válidos: qualquer número inteiro.
   * @type {FlexOrder}
   */
  @Input() flexOrder?: FlexOrder;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.removeClassesAndStyles();
    this.applyFlexChildProperties();
  }

  private removeClassesAndStyles(): void {
    const classList = this.el.nativeElement.classList;
    const flexChildClasses = Array.from(classList).filter((className: string) => className.startsWith('flex-'));
    flexChildClasses.forEach((className: string) => {
      this.renderer.removeClass(this.el.nativeElement, className);
    });
    this.renderer.removeStyle(this.el.nativeElement, 'flex-basis');
    this.renderer.removeStyle(this.el.nativeElement, 'flex');
  }

  private applyFlexChildProperties(): void {
    if (this.flexGrow !== undefined) {
      this.renderer.addClass(this.el.nativeElement, `flex-grow-${ this.flexGrow }`);
    }
    if (this.flexShrink !== undefined) {
      this.renderer.addClass(this.el.nativeElement, `flex-shrink-${ this.flexShrink }`);
    }
    if (this.flexBasis !== undefined) {
      if (this.isPercentage(this.flexBasis) || this.isPixel(this.flexBasis)) {
        this.renderer.setStyle(this.el.nativeElement, 'flex-basis', this.flexBasis);
      } else {
        this.renderer.addClass(this.el.nativeElement, `flex-basis-${ this.flexBasis }`);
      }
    }
    if (this.flexAlignSelf !== undefined) {
      this.renderer.addClass(this.el.nativeElement, `flex-align-self-${ this.flexAlignSelf }`);
    }
    if (this.flexOrder !== undefined) {
      this.renderer.addClass(this.el.nativeElement, `flex-order-${ this.flexOrder }`);
    }
    if (this.marFlex !== undefined) {
      this.renderer.setStyle(this.el.nativeElement, 'flex', this.marFlex);
    }
  }

  private isPercentage(value: string): boolean {
    return value.endsWith('%');
  }

  private isPixel(value: string): boolean {
    return value.endsWith('px');
  }
}

import { Injectable } from '@angular/core';
import { LocalStoreService } from '../local-store.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { User } from '../../../state/models/user.model';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { PersonEntityService } from '../../../state/entity-services/person-entity.service';
import { Person } from '../../../state/models/person';
import { Token } from '../../../state/models/token';
import { TokenEntityService } from '../../../state/entity-services/token-entity.service';
import * as Sentry from '@sentry/angular';
import { EntityManagementService } from '../../../state/entity-management/services/base/entity-management.service';

@Injectable({
  providedIn: 'root',
})
export class JwtAuthService {
  token: string | null = null;
  isAuthenticated: boolean = false;
  user: User | undefined = undefined;
  user$ = new BehaviorSubject<User | undefined>(this.user);
  signingIn: boolean = false;
  return: string = '';
  JWT_TOKEN = 'JWT_TOKEN';
  JWT_REFRESH_TOKEN = 'JWT_REFRESH_TOKEN';
  APP_USER = 'EGRET_USER';

  constructor(
    private entityManagementService: EntityManagementService,
    private tokenEntityService: TokenEntityService,
    private personEntityService: PersonEntityService,
    private ls: LocalStoreService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe(
      params => (this.return = params['return'] || '/'),
    );
  }

  get isLoggedIn(): boolean {
    return !!this.getJwtToken();
  }

  public signInCode(code: string, phone: string, document?: string): Observable<any> {
    this.signingIn = true;
    return this.authCode(code, phone, document).pipe(
      tap((res: AuthResponse) => {
        this.setUserAndToken(res);
      }),
      mergeMap((token: any) =>
        this.getSelf().pipe(
          tap(user => {
            this.setUserAndToken(token, user, !!token);
            this.signingIn = false;
          }),
        ),
      ),
      mergeMap(() =>
        this.personEntityService.authInvitesAcceptAll().pipe()
      ),
      catchError(error => {
        return throwError(error);
      }),
    );
  }

  public logout(redirect?: boolean) {
    this.entityManagementService.clearAllCache();
    this.ls.removeItem(this.APP_USER);
    this.ls.removeItem(this.JWT_TOKEN);
    this.ls.removeItem(this.JWT_REFRESH_TOKEN);
    if (redirect) {
      this.router.navigate(['/sessions/login']);
    }
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }

  getUser(): Person {
    return this.ls.getItem(this.APP_USER) as Person;
  }

  setUserAndToken(token: AuthResponse | null, user?: User | null, isAuthenticated: boolean = false) {
    const { accessToken, refreshToken } = token || {};
    this.isAuthenticated = isAuthenticated;
    this.token = accessToken;
    this.user = user;
    this.ls.setItem(this.APP_USER, user);
    this.ls.setItem(this.JWT_TOKEN, accessToken);
    this.ls.setItem(this.JWT_REFRESH_TOKEN, refreshToken);
    this.user$.next(user);
    this.setUserSentry(true);
  }

  setUserSentry(force?: boolean): void {
    const { id, email, phone, document } = this.getUser() || {};
    if (!document && force === undefined) {
      return;
    }

    Sentry.setUser(
      {
        id,
      }
    );
  }

  private authCode(code: string, phone: string, document?: string): Observable<Token> {
    return this.tokenEntityService.getToken(code, phone, document);
  }

  private getSelf(): Observable<User> {
    return this.personEntityService.getSelf();
  }

  refreshToken() {
    const body = {
      grantType: 'REFRESH_TOKEN',
      refreshToken: this.getJwtRefreshToken(),
    };
    return this.signIn(body).pipe(
      catchError(error => {
        return throwError(error);
      }),
    );
  }

  getJwtRefreshToken() {
    return this.ls.getItem(this.JWT_REFRESH_TOKEN);
  }


  signIn(body: any) {
    this.signingIn = true;
    return this.tokenEntityService.getTokenGeneral(body).pipe(
      tap((res) => {
        const { accessToken, refreshToken } = res as AuthResponse;
        this.setUserAndToken({ accessToken, refreshToken });
      }),
      mergeMap((res: any) =>
        this.getSelf().pipe(
          map(user => Object.assign({}, { token: res, user })),
        ),
      ),
      map((res: any) => {
        this.setUserAndToken(res.token, res.user, !!res);
        this.signingIn = false;
        return res;
      }),
      catchError(error => {
        return throwError(error);
      }),
    );
  }
}

interface AuthResponse {
  // The token
  refreshToken: string;
  accessToken: string;
}

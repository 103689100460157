import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ReportIncentiveParameter } from '../../state/models/reports';
import { UtilsService } from './utils.service';
import { OrganizationType } from '../../state/models/organization';

export type MetabaseNames = 44 | 45 | 48 | 53 | 52 | 59 | 58 | 55 | 60 | 61 | 51 | 57 | 72;

@Injectable({
  providedIn: 'root',
})
export class ReportsService {


  private metabaseConfig = {
    // RH
    44: {
      id: 44,
      title: 'Dados gerais',
      shortLabel: '',
      icon: 'dashboard_outlined',
      url: 'dados-gerais',
      minSubscriptions: 0,
      acl: ['HUMAN_RESOURCES', 'GROUP'],
    },
    72: {
      id: 72,
      title: 'Ações da Operadora',
      icon: 'health_and_safety_outlined',
      url: 'acoes',
      minSubscriptions: 30, //30
      acl: ['HUMAN_RESOURCES', 'GROUP']
    },
    45: {
      id: 45,
      title: 'Adesão da prevenção',
      icon: 'tornado_outlined',
      url: 'adesao',
      minSubscriptions: 30, //30
      acl: ['HUMAN_RESOURCES', 'GROUP']
    },
    // 61: {
    //   id: 61,
    //   title: 'Financeiro',
    //   icon: 'attach_money',
    //   url: 'relatorio-financeiro',
    //   minSubscriptions: 0,
    //   acl: ['HUMAN_RESOURCES', 'GROUP']
    // },
    48: {
      id: 48,
      title: 'Indicadores de Saúde',
      icon: 'monitor_heart_outlined',
      url: 'saude',
      minSubscriptions: 30, //30
      acl: ['HUMAN_RESOURCES', 'GROUP']
    },
    // Operadora
    53: {
      id: 53,
      title: 'Perfil populacional',
      shortLabel: 'Perfil',
      icon: 'groups',
      url: 'perfil-populacional',
      minSubscriptions: 0,
      acl: ['HEALTH_OPERATOR', 'BROKER']
    },
    52: {
      id: 52,
      title: 'Adesão',
      icon: 'tornado_outlined',
      url: 'adesao-operadora',
      minSubscriptions: 0,
      acl: ['HEALTH_OPERATOR', 'BROKER']
    },
    59: {
      id: 59,
      title: 'Custo com Prevenção',
      shortLabel: 'Custo',
      icon: 'attach_money',
      url: 'custos-prevencao',
      minSubscriptions: 0,
      acl: ['HEALTH_OPERATOR', 'BROKER']
    },
    58: {
      id: 58,
      title: 'Saúde',
      icon: 'monitor_heart_outlined',
      url: 'saude-operadora',
      minSubscriptions: 0,
      acl: ['HEALTH_OPERATOR', 'BROKER']
    },
    55: {
      id: 55,
      title: 'Exposição de risco financeiro',
      icon: 'question_mark',
      url: 'exposição-risco-financeiro',
      minSubscriptions: 0,
      acl: []
    },
    51: {
      id: 51,
      title: 'Receita do contrato incentivado*',
      icon: 'question_mark',
      url: 'receita-contrato-incentivado*',
      minSubscriptions: 0,
      acl: []
    },
    57: {
      id: 57,
      title: 'Satisfação',
      icon: 'question_mark',
      url: 'satisfacao',
      minSubscriptions: 0,
      acl: []
    },
    60: {
      id: 60,
      title: 'Sinistro',
      icon: 'ssid_chart',
      url: 'sinistro',
      minSubscriptions: 0,
      acl: ['HEALTH_OPERATOR', 'BROKER']
    },
  };

  constructor(private _httpClient: HttpClient, private utilsService: UtilsService) {
  }

  private getMetabaseProperty(id: MetabaseNames, property: keyof typeof this.metabaseConfig[44]): string | number | string[] {
    return this.metabaseConfig[id][property];
  }

  public getReportId(name: string): string {
    return `METABASE_${ this.getConfigByUrl(name) }`;
  }

  public getReportTitle(name: MetabaseNames): string {
    return this.getMetabaseProperty(name, 'title') as string;
  }

  public getReportShortLabel(name: MetabaseNames): string {
    const shortLabel = this.getMetabaseProperty(name, 'shortLabel');
    if (shortLabel) {
      return shortLabel as string;
    }
    return this.getMetabaseProperty(name, 'title') as string;
  }

  public getReportIcon(name: MetabaseNames): string {
    return this.getMetabaseProperty(name, 'icon') as string;
  }

  public getReportMinSubscription(name: MetabaseNames): number {
    return this.getMetabaseProperty(name, 'minSubscriptions') as number;
  }

  public getReportUrlId(name: MetabaseNames): string {
    return this.getMetabaseProperty(name, 'url') as string;
  }

  public getReportAcl(name: MetabaseNames): OrganizationType[] {
    return this.getMetabaseProperty(name, 'acl') as OrganizationType[];
  }

  public getReportUrl(name: MetabaseNames): string {
    return `/organizacoes/organizationId/estatisticas/${ this.getReportUrlId(name) }`;
  }

  public getConfigByUrl(url: string) {
    for (const key in this.metabaseConfig) {
      if (this.metabaseConfig[key].url === url) {
        return this.metabaseConfig[key].id;
      }
    }
    return null; // Retorna null se a URL não for encontrada
  }

  getListAllReportsOptions(aclList: string[]) {
    const options: any[] = [];
    Object.keys(this.metabaseConfig).forEach((key: any) => {
      const acl: string[] = this.getReportAcl(key);
      const allow: boolean = aclList.some(aclItem => acl.includes(aclItem));
      if (allow) {
        options.push({
          value: this.getMetabaseProperty(key, 'url'),
          label: this.getReportTitle(key),
          icon: this.getReportIcon(key),
          minSubscriptions: this.getReportMinSubscription(key),
        });
      }
    });
    return options;
  }

  getReportByOrganizationIdReportName(organizationId: any, metabaseIdName: string): Observable<any> {
    const body = {
      report: {
        reportTemplate: {
          name: this.getReportId(metabaseIdName),
        },
        organization: {
          id: organizationId,
        },
      },
    };
    return this.getReport(body);
  }

  getReportIncentive(organizationId: number, cycleId: number): Observable<ReportIncentiveParameter[]> {
    const body = {
      report: {
        reportTemplate: {
          name: 'INCENTIVE_PARAMETER',
        },
        organization: {
          id: organizationId,
        },
        reportParams: { cycleId }
      },
    };
    return this.getReport(body).pipe(
      map(response => response?.results)
    );
  }

  getReport(body: any): Observable<any> {
    const _baseUrlReports = `${ environment.apiUrl }/reports`;
    return this._httpClient.post(_baseUrlReports, body).pipe(
      map((response: any) => response?.report),
      catchError(err => {
        this.utilsService.setErrorToast(err);
        throw new Error(err);
      })
    );
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  organizationsList(): string {
    return '/organizacoes';
  }

  organizationsHome(id: unknown): string {
    return this.organizationsRhs(id);
  }

  organizationsBase(id: unknown): string {
    return [this.organizationsList(), id].join('/');
  }

  organizationsStatsGeneralData(id: unknown): string {
    return [this.organizationsBase(id), 'estatisticas', 'dados-gerais'].join('/');
  }

  organizationsAccessControl(id: unknown): string {
    return [this.organizationsList(), id, 'controle-de-acesso'].join('/');
  }

  organizationsRhs(id: unknown): string {
    return [this.organizationsList(), id, 'rhs'].join('/');
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cycles } from '../models/cycles';
import { DateService } from '../../shared/services/date.service';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CyclesEntityService extends BaseEntityService<Cycles> {

  constructor(
    httpClient: HttpClient,
    private dateService: DateService,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'cycle', plural: 'cycles' },
      routerParamsService
    );
    this.nameId = 'cycleId';
  }

  getCurrentCycleId(organizationIds: number | string): Observable<Cycles> {
    const containingDate = this.dateService.getLocalDateTime('YYYY-MM-DD');
    return this.getWithQuery({ organizationIds, containingDate }).pipe(
      map(cycles => {
        const [cycle] = cycles || [];
        return cycle;
      })
    );
  }
}

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from '../services/feature-flag.service';
import { FeatureFlagSearchNames } from '../../state/models/feature-flag';

/**
 * Referência: https://juristr.com/blog/2018/02/angular-permission-directive/
 */

@Directive({
  selector: '[featureFlagEnabled]',
})
export class FeatureFlagDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService,
  ) {
  }

  @Input()
  set featureFlagEnabled(flagName: FeatureFlagSearchNames[],) {
    this.updateView(flagName);
  }

  private updateView(permissionsName: FeatureFlagSearchNames[]) {
    if (this.featureFlagService.hasFlagEnable(permissionsName)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
  name: 'document',
})
export class DocumentPipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {
  }

  transform(value: string): string {
    return this.utilsService.maskCpfCnpj(value);
  }
}

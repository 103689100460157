import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { FeatureFlag, FeatureFlagSearch, FeatureFlagSearchNames } from '../models/feature-flag';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { map } from 'rxjs/operators';
import { FeatureFlagService } from '../../shared/services/feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagEntityService extends BaseEntityService<FeatureFlag> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, {
        singular: 'featureFlag',
        plural: 'featureFlags',
        url: 'feature-flags'
      },
      routerParamsService
    );
    this.nameId = 'featureFlagId';
  }



  getFeatureFlagSearches(queryParams: any): Observable<FeatureFlagSearch> {
    const featureFlagSearch: Params = this.removeUndefinedOrNull(queryParams);
    return this.httpClient.post(`${ environment.apiUrl }/feature-flag-searches`, { featureFlagSearch }).pipe(
      map((response: any) => response['featureFlagSearch']),
    );
  }

}

import { Injectable } from '@angular/core';
import { JwtAuthService } from './auth/jwt-auth.service';
import { environment } from '../../../environments/environment';
import * as amplitude from '@amplitude/analytics-browser';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
  amplitude: any = amplitude;
  identifyEvent: any;

  constructor(private jwtAuthService: JwtAuthService) {
    this.amplitude = amplitude;
    this.identifyEvent = new this.amplitude.Identify();
  }

  get getAmplitude() {
    return this.amplitude;
  }

  init(userId: any) {
    const user = this.jwtAuthService.getUser();
    userId = userId ?? user?.id;
    if (userId) {
      userId = userId.toString();
      for (let i = 0; i < (5 - userId.length); i++) {
        userId = '0' + userId;
      }
    }
    if (userId) {
      console.log('amplitude.init', userId);
      this.amplitude.init(environment.amplitude, userId);
    } else {
      this.amplitude.init(environment.amplitude);
    }
  }

  addEdentifyEvent(index: EventIndentifyAmplitude, value: any) {
    // console.log('Amplitude.addEdentifyEvent: ', index, value?.toString());
    this.identifyEvent.set(index, value?.toString());
  }

  setUserId(id: string) {
    console.log('Amplitude.setUserId: ', id);
    let userId = id;
    for (let i = 0; i < (5 - userId.length); i++) {
      userId = '0' + userId;
    }
    this.amplitude.setUserId(userId);
  }

 setIdentify() {
    this.amplitude.identify(this.identifyEvent);
  }

  sendEvent(eventName: EventNameAmplitude, props?: Record<string, any>) {
    this.amplitude.track(
      eventName,
      props
    );
    console.log('Amplitude: ', eventName, props);
  }
}

type EventIndentifyAmplitude =
  'user_id' |
  'user_type' |
  'org_type' |
  'org_id' |
  'org_name' |
  'parent_org_id' |
  'parent_org_type' |
  'parent_org_name' |
  'age' |
  'biological_sex'


type EventNameAmplitude =
  'status_list_downloaded' |
  'cell_phone_code_validated' |
  'manager_registered' |
  'url_signature_failed' |
  'cell_phone_is_valid' |
  'validation_failed' |
  'onboarding_finished' |
  'answer_created' |
  'answer_updated' |
  'form_submitted' |
  'previous_exams_skipped' |
  'previous_exams_sent' |
  'exams_uploaded' |
  'new_exams_request_downloaded' |
  'contact_support_clicked' |
  'patient_summary_viewed' |
  'patient_summary_tab_clicked' |
  'patient_summary_referral_downloaded' |
  'patient_summary_self_care_clicked' |
  'page_viewed';

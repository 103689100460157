{
  "name": "painel",
  "version": "1.11.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port=7200 --open --host 0.0.0.0 --disable-host-check ",
    "build": "ng build --configuration=production --aot=true --optimization=true",
    "build:stag": "ng build --configuration=stag --aot=true --optimization=true",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "sonar": "sonar-scanner",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org mar-saude --project patient ./dist/patient && sentry-cli sourcemaps upload --org mar-saude --project patient ./dist/patient",
    "changelog-version": "node generate-changelog-update-version.js",
    "commit-version": "git add . && git commit -m'Version name: '$(grep -m1 version package.json | awk -F: '{ print $2 }' | sed 's/[\", ]//g')''",
    "update-angular": "ng update @angular/core @angular/cli @angular/cdk @angular-devkit/build-angular @angular/compiler-cli @angular/material @angular/material-experimental",
    "release": "npm run changelog-version && npm run commit-version && git push && git checkout main && git pull  && git merge --no-ff dev && git push && git checkout -"
  },
  "private": true,
  "dependencies": {
    "@amplitude/analytics-browser": "^2.9.3",
    "@angular/animations": "^18.1.0",
    "@angular/cdk": "^18.1.0",
    "@angular/common": "^18.1.0",
    "@angular/compiler": "^18.1.0",
    "@angular/core": "^18.1.0",
    "@angular/forms": "^18.1.0",
    "@angular/material": "^18.1.0",
    "@angular/material-experimental": "^18.1.0",
    "@angular/platform-browser": "^18.1.0",
    "@angular/platform-browser-dynamic": "^18.1.0",
    "@angular/router": "^18.1.0",
    "@ngx-formly/core": "^6.3.0",
    "@ngx-formly/material": "^6.3.0",
    "@sentry/angular": "^8.13.0",
    "@sentry/cli": "^2.32.1",
    "dayjs": "^1.11.10",
    "iframe-resizer": "^4.3.11",
    "lodash": "^4.17.21",
    "ng2-currency-mask": "^13.0.3",
    "ngx-file-drop": "^16.0.0",
    "ngx-mask": "^17.0.7",
    "numeral": "^2.0.6",
    "rxjs": "~7.8.0",
    "tslib": "^2.6.3",
    "zone.js": "~0.14.7"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.0",
    "@angular/cli": "^18.1.0",
    "@angular/compiler-cli": "^18.1.0",
    "@types/jasmine": "~5.1.0",
    "@types/numeral": "^2.0.5",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "semver": "^7.6.0",
    "sonar-scanner": "^3.1.0",
    "typescript": "~5.4.2"
  }
}
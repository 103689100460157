import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-masked-type',
  template: `
    <mat-form-field class="full-width" appearance="outline" [floatLabel]="props.floatLabel || 'auto'">
      <mat-label *ngIf="props.label">{{ props.label }}</mat-label>
      <input
        matInput
        [id]="id"
        [mask]="mask"
        [type]="type || 'text'"
        [readonly]="props.readonly"
        [required]="props.required"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [tabIndex]="props.tabindex"
        [placeholder]="props.placeholder"
        [dropSpecialCharacters]="props.keepMask ?? true"
        [decimalMarker]="props?.decimalMarker || '.'"
        [thousandSeparator]="props?.thousandSeparator || ','"
      />
      <mat-error *ngIf="showError">
        <small *ngIf="formControl.errors" [innerHTML]="getTypeError()"></small>
      </mat-error>
    </mat-form-field>

  `,
})
export class FormlyFieldMaskedTypeComponent extends FieldType<FieldTypeConfig> {
  get type() {
    return this.props.type ?? 'text';
  }

  get mask() {
    return this.props['mask'] ?? '(00) 0000-0000';
  }

  getTypeError() {
    let message = '';
    Object.keys(this.formControl.errors).forEach(error => {
      if (error === 'required') {
        message = 'Este campo é obrigatório';
        return;
      }
      if (error === 'mask') {
        message = 'Formatação inválida, continue digitando';
        return;
      }
      message = this.formControl.errors[error]?.message || 'Verifique suas informações';
    });
    return message;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReportsService } from './reports.service';
import { OrganizationType } from '../../state/models/organization';

export interface ILayoutConf {
  toolbarNav?: boolean;
  menu?: Menu[];
  menuFooter?: Menu[];
  updatedAt?: number;
  titlePage?: string;
}


@Injectable({
  providedIn: 'root'
})
export class MenuLayoutService {
  public layoutConf: ILayoutConf = {
    toolbarNav: true,
  };
  layoutConfSubject = new BehaviorSubject<ILayoutConf>(this.layoutConf);
  layoutConf$ = this.layoutConfSubject.asObservable();
  menu: Menu[] = [];
  menuFooter: Menu[] = [];

  constructor(
    private reportsService: ReportsService,
  ) {
    this.setMenu();
    this.setMenuFooter();
  }

  updateLayoutConfig() {
    this.setLayout({ updatedAt: new Date().getTime() });
  }

  setLayout(layoutConfSet: ILayoutConf) {
    this.layoutConf = { ...this.layoutConf, ...layoutConfSet };
    this.layoutConfSubject.next(this.layoutConf);
  }

  setMenuFooter() {
    this.menuFooter = [
      {
        type: 'divider'
      },
      {
        icon: 'person_outline',
        label: 'Perfil',
        shortLabel: 'Perfil',
        type: 'item',
        showPath: false,
        path: '/organizacoes/organizationId/perfil',
        acl: ['HUMAN_RESOURCES', 'GROUP'],
        actions: [
          'ORGANIZATION_TYPE', 'ORGANIZATION_ID_REQUIRED', 'ACTIVE', 'ORGANIZATION_ID_REPLACE',
        ]
      },
      {
        icon: 'manage_accounts',
        label: 'Acessos',
        shortLabel: 'Acessos',
        type: 'item',
        showPath: false,
        path: '/organizacoes/organizationId/controle-de-acesso',
        acl: ['HEALTH_OPERATOR', 'BROKER'],
        actions: [
          'ORGANIZATION_TYPE', 'ORGANIZATION_ID_REQUIRED', 'ACTIVE', 'ORGANIZATION_ID_REPLACE',
        ]
      },
      {
        icon: 'logout',
        label: 'Sair',
        shortLabel: 'Sair',
        type: 'item',
        path: '/sessions/logout',
      },
    ];
    this.setLayout({ menuFooter: this.menuFooter });
  }

  setMenu() {
    this.menu = [
      {
        icon: 'business',
        label: 'Organizacões',
        shortLabel: 'Orgs',
        type: 'item',
        path: '/organizacoes',
        actions: ['CHECK_ONLY_ONE_ORGANIZATION', 'ACTIVE']
      },
      {
        icon: 'person_search',
        label: 'Cliente',
        shortLabel: 'Cliente',
        type: 'item',
        showPath: false,
        path: '/organizacoes/organizationId/rhs',
        acl: ['HEALTH_OPERATOR', 'BROKER'],
        actions: [
          'ORGANIZATION_TYPE', 'ORGANIZATION_ID_REQUIRED', 'ACTIVE', 'ORGANIZATION_ID_REPLACE',
        ]
      },
      {
        // DADOS GERAIS
        icon: this.reportsService.getReportIcon(44),
        label: this.reportsService.getReportTitle(44),
        shortLabel: this.reportsService.getReportShortLabel(44),
        path: this.reportsService.getReportUrl(44),
        type: 'item',
        showPath: false,
        acl: this.reportsService.getReportAcl(44),
        actions: [
          'ORGANIZATION_TYPE', 'ORGANIZATION_ID_REQUIRED', 'ACTIVE', 'ORGANIZATION_ID_REPLACE',
        ]
      },
      {
        // DESCONTO INCENTIVADO
        icon: 'sell',
        label: 'Desconto incentivo',
        shortLabel: 'Desconto incentivo',
        path: '/organizacoes/organizationId/desconto-incentivado',
        type: 'item',
        showPath: false,
        acl: ['HUMAN_RESOURCES', 'GROUP'],
        actions: [
          'ORGANIZATION_TYPE', 'CHECK_CYCLE_INCENTIVE', 'ORGANIZATION_ID_REQUIRED', 'ACTIVE', 'ORGANIZATION_ID_REPLACE',
        ]
      },
      {
        // ADESAO DA PREVENCAO
        icon: this.reportsService.getReportIcon(45),
        label: this.reportsService.getReportTitle(45),
        shortLabel: this.reportsService.getReportShortLabel(45),
        path: this.reportsService.getReportUrl(45),
        type: 'item',
        showPath: false,
        acl: this.reportsService.getReportAcl(45),
        actions: [
          'ORGANIZATION_TYPE', 'MIN_SUBSCRIPTIONS_30_OR_NO_CYCLE_INCENTIVE', 'ORGANIZATION_ID_REQUIRED', 'ACTIVE', 'ORGANIZATION_ID_REPLACE',
        ]
      },
      {
        // ACOES DA OPERADORA
        icon: this.reportsService.getReportIcon(72),
        label: this.reportsService.getReportTitle(72),
        shortLabel: this.reportsService.getReportShortLabel(72),
        path: this.reportsService.getReportUrl(72),
        type: 'item',
        showPath: false,
        acl: this.reportsService.getReportAcl(72),
        actions: [
          'ORGANIZATION_TYPE', 'ORGANIZATION_ID_REQUIRED', 'MIN_SUBSCRIPTION_30', 'ACTIVE', 'ORGANIZATION_ID_REPLACE',
        ]
      },
      {
        // INDICADORES DE SAUDE
        icon: this.reportsService.getReportIcon(48),
        label: this.reportsService.getReportTitle(48),
        shortLabel: this.reportsService.getReportShortLabel(48),
        path: this.reportsService.getReportUrl(48),
        type: 'item',
        showPath: false,
        acl: this.reportsService.getReportAcl(48),
        actions: [
          'ORGANIZATION_TYPE', 'ORGANIZATION_ID_REQUIRED', 'MIN_SUBSCRIPTION_30', 'ACTIVE', 'ORGANIZATION_ID_REPLACE',
        ]
      },
      {
        // Perfil populacional
        icon: this.reportsService.getReportIcon(53),
        label: this.reportsService.getReportTitle(53),
        shortLabel: this.reportsService.getReportShortLabel(53),
        path: this.reportsService.getReportUrl(53),
        type: 'item',
        showPath: false,
        acl: this.reportsService.getReportAcl(53),
        actions: [
          'ORGANIZATION_TYPE', 'ORGANIZATION_ID_REQUIRED', 'ACTIVE', 'ORGANIZATION_ID_REPLACE',
        ]
      },
      {
        // Adesão HO
        icon: this.reportsService.getReportIcon(52),
        label: this.reportsService.getReportTitle(52),
        shortLabel: this.reportsService.getReportShortLabel(52),
        path: this.reportsService.getReportUrl(52),
        type: 'item',
        showPath: false,
        acl: this.reportsService.getReportAcl(52),
        actions: [
          'ORGANIZATION_TYPE', 'ORGANIZATION_ID_REQUIRED', 'ACTIVE', 'ORGANIZATION_ID_REPLACE',
        ]
      },
      {
        // Custos da prevenção
        icon: this.reportsService.getReportIcon(59),
        label: this.reportsService.getReportTitle(59),
        shortLabel: this.reportsService.getReportShortLabel(59),
        path: this.reportsService.getReportUrl(59),
        type: 'item',
        showPath: false,
        acl: this.reportsService.getReportAcl(59),
        actions: [
          'ORGANIZATION_TYPE', 'ORGANIZATION_ID_REQUIRED', 'ACTIVE', 'ORGANIZATION_ID_REPLACE',
        ]
      },
      {
        // Saúde
        icon: this.reportsService.getReportIcon(58),
        label: this.reportsService.getReportShortLabel(58),
        shortLabel: this.reportsService.getReportTitle(58),
        path: this.reportsService.getReportUrl(58),
        type: 'item',
        showPath: false,
        acl: this.reportsService.getReportAcl(58),
        actions: [
          'ORGANIZATION_TYPE', 'ORGANIZATION_ID_REQUIRED', 'ACTIVE', 'ORGANIZATION_ID_REPLACE',
        ]
      },
      {
        // Exposição de risco financeiro
        icon: this.reportsService.getReportIcon(55),
        label: this.reportsService.getReportTitle(55),
        shortLabel: this.reportsService.getReportShortLabel(55),
        path: this.reportsService.getReportUrl(55),
        type: 'item',
        showPath: false,
        acl: this.reportsService.getReportAcl(55),
        actions: [
          'ORGANIZATION_TYPE', 'ORGANIZATION_ID_REQUIRED', 'ACTIVE', 'ORGANIZATION_ID_REPLACE',
        ]
      },
      {
        // Sinistro
        icon: this.reportsService.getReportIcon(60),
        label: this.reportsService.getReportTitle(60),
        shortLabel: this.reportsService.getReportShortLabel(60),
        path: this.reportsService.getReportUrl(60),
        type: 'item',
        showPath: false,
        acl: this.reportsService.getReportAcl(60),
        actions: [
          'ORGANIZATION_TYPE', 'ORGANIZATION_ID_REQUIRED', 'ACTIVE', 'ORGANIZATION_ID_REPLACE',
        ]
      },
    ];
    this.setLayout({ menu: this.menu });
  }

  setTitle(titlePage: string) {
    this.layoutConf = { ...this.layoutConf, titlePage };
  }
}

export interface Menu {
  path?: string;
  showPath?: boolean;
  acl?: OrganizationType[],
  actions?: MenuActions[];
  icon?: string;
  label?: string;
  shortLabel?: string;
  active?: boolean;
  type: 'item' | 'submenu' | 'divider';
  extras?: {
    minSubscriptions?: number
  }
  /*
  * Do not set this property
  * */
  toRemove?: boolean;
}

export type MenuActions =
  'MIN_SUBSCRIPTION_30'
  | 'ACTIVE'
  | 'ORGANIZATION_ID_REQUIRED'
  | 'ORGANIZATION_ID_REPLACE'
  | 'CHECK_CYCLE_INCENTIVE'
  | 'MIN_SUBSCRIPTIONS_30_OR_NO_CYCLE_INCENTIVE'
  | 'CHECK_ONLY_ONE_ORGANIZATION'
  | 'ORGANIZATION_TYPE';

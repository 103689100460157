import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonRegistration, User } from '../models/user.model';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Person } from '../models/person';
import { CrmPersons } from '../models/crm-persons';

@Injectable({
  providedIn: 'root'
})
export class PersonEntityService extends BaseEntityService<Person> {
  baseUrl: string = environment.apiUrl;

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'person', plural: 'persons' },
      routerParamsService
    );
    this.nameId = 'personId';
  }

  getSelf(): Observable<User> {
    return this.httpClient
      .get(`${ this.entityUrl }/self`)
      .pipe(map((response: any) => response[this.singularEntityName]));
  }

  generateSelfieLink(personId: number): Observable<string> {
    const body = {};
    return this.httpClient
      .post(`${ this.entityUrl }/${ personId }/generate-selfie-url`, body)
      .pipe(map((response: any) => response[this.singularEntityName]?.selfieUrl));
  }

  crmPersons(personIds: number): Observable<CrmPersons> {
    return this.httpClient
      .get(`${ this.baseUrl }/crm-persons`, { params: { personIds } })
      .pipe(
        map((response: any) => {
          const [crmPerson] = response?.crmPersons || [];
          return crmPerson;
        })
      );
  }

  crmPersonsSetPreviousExamsAlreadySent(crmPersonId: number): Observable<string> {
    return this.httpClient
      .post(`${ this.baseUrl }/crm-persons/${ crmPersonId }/set-previous-exams-already-sent`, {})
      .pipe(
        map(() => {
          return 'ok';
        })
      );
  }

  authInvitesAcceptAll(): Observable<string> {
    return this.httpClient
      .post(`${ this.baseUrl }/auth-invites/accept-all`, {})
      .pipe(
        map(() => {
          return 'ok';
        })
      );
  }

  personRegistrations(document: string): Observable<PersonRegistration> {
    return this.httpClient
      .get(`${ this.baseUrl }/person-registrations/`, { params: { document } })
      .pipe(
        map((response: any) => {
          return response?.personRegistration;
        })
      );
  }
}

import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor() {
  }

  sendWhatsAppMessage(msg: string): void {
    window.open(`https://wa.me/5511912742944?text=${ encodeURIComponent(msg) }`, '_blank');
  }

  getConfirmSmsCode() {
    return {
      title: `Você precisa de ajuda com o código do sms?`,
      message: `Entre em contato com nossa equipe para obter auxilio em caso de dificuldades.`,
      buttonConfirm: {
        label: 'Sim, entrar em contato',
        show: true
      },
      buttonCancel: {
        label: 'Não, obrigado',
        show: true
      }
    };
  }

  getConfirmCellphone(phoneAreaCode: string, phoneEnding: string) {
    return {
      title: `Você reconhece esse celular?`,
      message: `(${ phoneAreaCode }) xxxxx-xx${ phoneEnding }? Enviaremos um codigo para esse celular.`,
      buttonConfirm: {
        label: 'Sim, é meu celular',
        show: true
      },
      buttonCancel: {
        label: 'Não, entrar em contato',
        show: true
      }
    };
  }

  setInputByType(fieldTypeVar: string) {
    const fieldName = {
      DOCUMENT: 'document',
      PHONE: 'phone',
      PHONE_CODE: 'phoneCode',
      SIGNUP: 'signup',
    };

    const cpf: FormlyFieldConfig[] = [
      {
        type: 'input',
        key: 'document',
        defaultValue: '',
        props: {
          label: 'CPF',
          mask: '000.000.000-00',
          attributes: {
            inputmode: 'numeric',
          },
          required: true,
        },
        validators: {
          validation: ['cpf'],
        },
        expressions: {
          hide: (field: FormlyFieldConfig) => {
            return 'document' !== fieldName[fieldTypeVar];
          },
        },
      },
    ]


    return [
      {
        type: 'masked',
        key: 'document',
        defaultValue: '',
        props: {
          label: 'CPF',
          mask: '000.000.000-00',
          attributes: {
            inputmode: 'numeric',
          },
          required: true,
        },
        validators: {
          validation: ['cpf'],
        },
        validationMessages: ['cpf'],
        expressions: {
          hide: (field: FormlyFieldConfig) => {
            return 'document' !== fieldName[fieldTypeVar];
          },
        },
      },
      {
        type: 'input',
        key: 'name',
        defaultValue: '',
        props: {
          label: 'Nome completo',
          required: true,
          autofocus: true,
        },
        expressions: {
          hide: (field: FormlyFieldConfig) => {
            return 'signup' !== fieldName[fieldTypeVar];
          },
        },
      },
      {
        type: 'masked',
        key: 'birthdate',
        defaultValue: '',
        props: {
          mask: '00/00/0000',
          keepMask: false,
          label: 'Data de nascimento',
          placeholder: '00/00/0000',
          required: true,
          readonly: false,
        },
        expressions: {
          hide: (field: FormlyFieldConfig) => {
            return 'signup' !== fieldName[fieldTypeVar];
          },
        },
      },
      {
        key: 'biologicalSex',
        type: 'buttonToggle',
        defaultValue: '',
        props: {
          required: true,
          options: [
            { label: 'Masculino', value: 'MALE' },
            { label: 'Feminino', value: 'FEMALE' },
          ],
        },
        expressions: {
          hide: (field: FormlyFieldConfig) => {
            return 'signup' !== fieldName[fieldTypeVar];
          },
        },
      },

      {
        type: 'masked',
        key: 'phone',
        defaultValue: '',
        props: {
          label: 'Celular',
          placeholder: 'Digite seu telefone...',
          mask: '(00)00000-0000',
          required: true,
          attributes: {
            inputmode: 'numeric',
          },
        },
        expressions: {
          hide: (field: FormlyFieldConfig) => {
            return !['phone', 'signup'].includes(fieldName[fieldTypeVar]);
          },
        },
      },
      {
        type: 'masked',
        key: 'phoneCode',
        defaultValue: '',
        props: {
          label: 'Código',
          placeholder: 'Digite aqui o codigo...',
          attributes: {
            autocomplete: 'one-time-code',
            inputmode: 'numeric',
          },
          floatLabel: 'always',
          mask: '00 00 00',
          required: true,
        },
        focus: true,
        expressions: {
          hide: (field: FormlyFieldConfig) => {
            return 'phoneCode' !== fieldName[fieldTypeVar];
          },
        },
      },
    ];
  }

}

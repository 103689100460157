import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthStatementsEntityService } from '../../state/entity-services/auth-statements-entity.service';
import { AuthStatements } from '../../state/models/auth-statements';
import { OrganizationEntityService } from '../../state/entity-services/organization-entity.service';
import { OrganizationService } from '../services/organization.service';
import { AmplitudeService } from '../services/amplitude.service';
import { Person } from '../../state/models/person';
import { DateService } from '../services/date.service';

@Injectable()
export class OrganizationListGuard implements CanActivate {
  constructor(
    private dateService: DateService,
    private jwtService: JwtAuthService,
    private amplitudeService: AmplitudeService,
    private organizationService: OrganizationService,
    private organizationEntityService: OrganizationEntityService,
    private authStatementsEntityService: AuthStatementsEntityService,
    private router: Router, private jwtAuthService: JwtAuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.jwtAuthService.isLoggedIn) {
      this.router.navigate(['/sessions/login'], {
        queryParams: {
          return: state.url,
        },
      });
      return of(false);
    }

    return this.authStatementsEntityService.getWithQuery({
      principalPersonIds: this.jwtAuthService.getUser().id,
    }).pipe(
      switchMap((authStatements: AuthStatements[]): Observable<boolean> => {
        this.organizationService.setOrganization(null);
        const user: Person = this.jwtService.getUser();
        this.amplitudeService.setUserId(user.id.toString());
        const { personProperties } = user;
        const { birthdate, biologicalSex } = personProperties;
        this.amplitudeService.addEdentifyEvent('biological_sex', biologicalSex);
        this.amplitudeService.addEdentifyEvent('age', this.dateService.diffByYears(birthdate).toString());


        if (authStatements.some(as => as.role.name === 'ADMIN')) {
          this.amplitudeService.addEdentifyEvent('user_type', 'ADMIN ');
          this.organizationService.setInfoPermissions({ hasAdmin: true }, authStatements);
          return of(true);
        }

        const authStatementManagers = authStatements.filter(as => as.role.name === 'MANAGER');

        this.amplitudeService.addEdentifyEvent('user_type', 'MANAGER ');

        if (authStatementManagers.length > 1) {
          this.organizationService.setInfoPermissions({ hasManyOrgs: true }, authStatements);
          return of(true);
        }

        this.organizationService.setInfoPermissions({ hasManyOrgs: false, hasAdmin: false }, authStatements);
        const [authStatementManager] = authStatementManagers;
        const organizationId = authStatementManager?.resource?.organization?.id;

        return this.organizationEntityService.getById(organizationId).pipe(
          map(organization => {
            this.organizationService.setOrganization(organization);
            this.router.navigateByUrl(this.organizationService.getDefaultUrl());
            return true;
          })
        );
      })
    );
  }

}



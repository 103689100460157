declare function require(moduleName: any): any;

export const environment = {
  amplitude: '78eff6d67bbb6691d20a56a050ec2916',
  typeForm: 'CzfYt6Mh',
  production: true,
  env: 'prod',
  apiUrlMock: 'http://localhost:3000',
  apiUrl: 'https://api.marsaude.net/v1',
  loginRedirect: 'https://app.marsaude.net',
  version: require('../../package.json').version,
  build: require('../../package.json').build,
  company: {
    name: 'Mar Saúde',
  },
};
